<template>
  <div>
    <slot />
  </div>
</template>

<script setup lang="ts">
const language = useCurrentLanguage()
const styles = await useCurrentStyles()
const data = await useInitData()
const domain = computed(() => data.value?.siteInfo?.domain || 'sv')

useHead({
  htmlAttrs: {
    lang: language.value,
    style: styles,
    class: 'bg-header-footer-background',
  },
  link: [
    {
      rel: 'apple-touch-icon',
      href: `/favicons/${domain.value}/apple-touch-icon.png`,
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: `/favicons/${domain.value}/favicon.ico`,
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: `/favicons/${domain.value}/favicon.svg`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/${domain.value}/favicon-96x96.png`,
    },
    { rel: 'manifest', href: `/favicons/${domain.value}/site.webmanifest` },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/${domain.value}/web-app-manifest-192x192.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/${domain.value}/web-app-manifest-512x512.png`,
    },
    {
      rel: 'mask-icon',
      href: `/favicons/${domain.value}/safari-pinned-tab.svg`,
      color: '#5bbad5',
    },
  ],
})
</script>
